import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import CTAButton from "../components/CTAButton"
import ViewPricingSection from "../components/ViewPricingSection";
import greenCheck from "../img/green-check.svg"
import play from "../img/play.svg"
import HashtagHidden from "../components/HashtagHidden";
import ExpertsSection from "../components/ExpertsSection";

// eslint-disable-next-line
export const ProductPageTemplate = ({
  title,
  heading,
  header,
  description,
  openers,
  fullImage,
  features,
  closing,
  pricing
}) => {
  return (
    <>
      <section className="section is-small no-padding-bottom">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Compensation Management & Benchmarking Program - Kamsa</title>
            <meta property="og:title" content="Compensation Management & Benchmarking Program - Kamsa" />
            <meta name="description" content="Kamsa is a compensation management system that combines global salary data & expert support from compensation consultants for simplified compensation planning." />
          </Helmet>
        </div>
      </section>
      <section className="section section--gradient is-small">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <div className="block">
                <h4 className="is-h4">{ heading }</h4>
                <h1 className="title is-h1">{ header }</h1>
              </div>
              <div className="block">
                <h3 className="subtitle is-h3">{ description }</h3>
              </div>
              <div className="block">
                <div className="openers--container">
                  { openers.map((value, index) =>
                    <div key={index} className="columns is-mobile openers--row">
                      <div className="column is-narrow">
                        <img className="openers--icon" src={greenCheck} alt="Green checkmark" />
                      </div>
                      <div className="column is-flex is-align-items-center">
                        <p className="openers--description">{value.description}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="block">
                <a className="button is-large is-link " href="/kamsa">
                  Watch Demo
                  <div className="icon ml-3">
                    <img src={play} alt="Play" />
                  </div>
                </a>
              </div>
            </div>
            <div className="column" />
          </div>
          </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <div className="block">
            <h2 className="title is-h1">Key Features</h2>
          </div>
            { features.map((value, index) =>
              <div key={index} className={`columns ${!!(index % 2) ? 'level-right' : 'level-left'}`}>
                <div className="column is-8">
                  <img className="features--image" src={value.image.publicURL} alt={value.title} />
                </div>
                <div className="column is-4">
                  <div className="columns is-mobile">
                    <div className="column is-narrow is-hidden-desktop is-hidden-tablet">
                      <div className="block">
                        <img className="features--icon" src={value.icon.publicURL} alt={value.title} />
                      </div>
                    </div>
                    <div className="column">
                      <div className="block is-hidden-mobile">
                        <img className="features--icon" src={value.icon.publicURL} alt={value.title} />
                      </div>
                      <div className="block">
                        <h2 className="subtitle is-h2 m-0">{value.title}</h2>
                      </div>
                      <div className="block">
                        <p>{value.description}</p>
                      </div>
                      <CTAButton link={value.buttonLink} text={value.buttonText} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
      </section>
      <section className="section section--primary is-small">
        <div className="container">
          <ExpertsSection
            title={closing.title}
            text={closing.text}
            buttonLink={closing.buttonLink}
            buttonText={closing.buttonText}
            image={closing.image}
          />
        </div>
      </section>
      <section className="section section--gradient is-medium">
          <ViewPricingSection
              title={pricing.title}
              subtitle={pricing.subtitle}
              buttonLink={pricing.buttonLink}
              buttonText={pricing.buttonText}
          />
      </section>
    </>
  );
};

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  header: PropTypes.string,
  description: PropTypes.string,
  openers: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
  })),
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  features: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
  })),
  closing: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  pricing: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  })
};

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        header={frontmatter.header}
        description={frontmatter.description}
        openers={frontmatter.openers}
        fullImage={frontmatter.full_image}
        features={frontmatter.features}
        closing={frontmatter.closing}
        pricing={frontmatter.productPagePricing}
      />
      <HashtagHidden hashtags={frontmatter.productPageHashtags} />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        header
        description
        openers {
          description
        }
        features {
          buttonLink
          buttonText
          title
          description
          image {
            publicURL
          }
          icon {
            publicURL
          }
        }
        closing {
          title
          text
          buttonLink
          buttonText
          image {
            publicURL
          }
        }
        productPagePricing {
          title
          subtitle
          buttonLink
          buttonText
        }
        productPageHashtags {
          title
        }
      }
    }
  }
`;
